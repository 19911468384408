import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet de zones arenoses amb un capell de fins a 10 cm de diàmetre que al principi és convex per passar a pla i cobert al centre d’esquametes llanoses, atapeïdes i curtes. És de color marró ocre brillant, a vegades un poc rogenc. Les làmines són escotades o un poc decurrents de color daurat o groc brut. El peu, llarg, acaba amb una base bulbosa que engloba restes d’arena i presenta una cortina molt visible que dura molt de temps que sembla l’anell. Les espores són ocres en massa, en forma de ronyó, de 10-15 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      